<template>
  <form @submit.prevent="submit">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ schedule.name }}
          <span v-if="schedules.length > 1"> + {{ schedules.length - 1 }}</span>
        </p>
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('pages.closure_schedule.mail_1_column_title')">
          <b-datepicker
            v-model="returnUser.mail1"
            name="enddate"
            icon="calendar-alt"
            placeholder="End date"
          />
          <b-button type="is-warning" class="is-small" @click="() => {returnUser.mail1 = null}">
            No Date
          </b-button>
        </b-field>
        <b-field :label="$t('pages.closure_schedule.mail_2_column_title')">
          <b-datepicker
            v-model="returnUser.mail2"
            name="enddate"
            icon="calendar-alt"
            placeholder="End date"
          />
          <b-button type="is-warning" class="is-small" @click="() => {returnUser.mail2 = null}">
            No Date
          </b-button>
        </b-field>
        <b-field :label="$t('pages.closure_schedule.mail_template_picker')">
          <b-select v-model="returnUser.template" :placeholder="$t('pages.closure_schedule.mail_template_picker_placeholder')">
            <option v-for="grp in templates" :key="grp.id" :value="grp.id">
              {{ grp.templateName }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button type="submit" class="button is-info is-fullwidth">
          {{ $t('global.submit') }}
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import enduserClosureMail from '@/services/v3/enduserClosureMail'

export default {
  props: {
    schedules: {
      type: Array,
      default: null
    },
    collectGroup: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      templates: [],
      schedule: {},
      returnUser: {
        mail1: null,
        mail2: null,
        template: null,
        reminder: null
      }
    }
  },
  computed: {
  },
  mounted: async function () {
    this.schedule = this.schedules[0]
    this.returnUser.mail1 = this.schedule.mail1 ? new Date(this.schedule.mail1) : null
    this.returnUser.mail2 = this.schedule.mail2 ? new Date(this.schedule.mail2) : null
    this.returnUser.template = this.schedule.template
    this.returnUser.reminder = this.schedule.reminderStatus
    try {
      var result = await enduserClosureMail.get(this.collectGroup)
      result.sort((a, b) => {
        const ga = a.templateName.toLowerCase()
        const gb = b.templateName.toLowerCase()

        if (ga < gb) {
          return -1
        }

        if (ga > gb) {
          return 1
        }

        return 0
      })
      this.templates = result
    } catch (error) {
      this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
    }
  },
  methods: {
    async submit () {
      try {
        this.$emit('submit', {
          mail1: this.returnUser.mail1 ? this.returnUser.mail1.toLocaleString() : this.returnUser.mail1,
          mail2: this.returnUser.mail2 ? this.returnUser.mail2.toLocaleString() : this.returnUser.mail2,
          template: this.returnUser.template,
          reminder: this.returnUser.reminder
        })
        return
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    }
  }
}
</script>

<style></style>
