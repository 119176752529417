<template>
  <div class="modal-card" style="height: 90vh; width: auto">
    <div class="modal-card-body">
      <b-loading v-model="isLoading" />
      <div class="flex is-justify-content-space-between">
        <div class="is-flex is-align-items-center">
          <h1> {{ viewingSi.ref }}</h1>
          <div :class="[`has-background-${getColor(viewingSi)}`, 'px-3', 'py-1', 'ml-3']">
            {{ getStatus(viewingSi) }}
          </div>
        </div>

        <div class="is-flex is-align-items-center">
          <span class="mr-3">Version:</span>
          <b-select v-model="viewingSi" placeholder="version" @input="handleVersionChange">
            <option
              v-for="data in shippingInstructions"
              :key="data.id"
              :value="data"
            >
              {{ data.version }}
            </option>
          </b-select>
        </div>
      </div>

      <div class="columns is-multiline is-mobile">
        <div class="column is-half">
          <b-field label="SI Date">
            <b-input :value="viewingSi.createdAt" />
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="PO Number">
            <b-input
              :value="viewingSi.purchaseOrderNumber"
            />
          </b-field>
        </div>
        <div class="column is-full">
          <b-field label="Bill of Landing">
            <b-input :value="viewingSi.billOfLading" />
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Invoice">
            <b-input :value="viewingSi.invoice" />
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="USD Rate">
            <b-input :value="viewingSi.usdRate" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Quantity">
            <b-input :value="viewingSi.quantity" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Material">
            <b-input :value="viewingSi.material" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="OB">
            <b-input :value="viewingSi.ob" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Departure Plant">
            <b-input :value="viewingSi.departurePlantId" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Loading Port">
            <b-input :value="viewingSi.loadingPortId" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="ETD">
            <b-input :value="viewingSi.estimatedTimeOfDeparture" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Arrival Port">
            <b-input :value="viewingSi.arrivalPortId" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Delivered At">
            <b-input :value="viewingSi.deliveredAt" />
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Delivered Qty">
            <b-input :value="viewingSi.deliveredQuantity" />
          </b-field>
        </div>
        <div class="column is-full">
          <b-field label="Processor">
            <b-input :value="`${viewingSi.processorId} - ${viewingSi.processorName} - ${viewingSi.processorConsignee}`" />
          </b-field>
        </div>
        <div class="column is-full">
          <b-field label="Enduser">
            <b-input :value="`${viewingSi.enduserId} - ${viewingSi.enduserName} - ${viewingSi.enduserConsignee}`" />
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { columns } from './columns.js'
import gql from 'graphql-tag'

export default {
  props: {
    si: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      isLoading: false,
      shippingInstructions: [],
      viewingSi: ''

    }
  },
  async mounted  () {
    await this.getShippmentInstructions()
    this.viewingSi = this.shippingInstructions[0]
  },
  methods: {
    async getShippmentInstructions () {
      this.isLoading = true
      try {
        const columnFields = columns.map(col => col.field)

        const builder = this.$gql.query({
          operation: 'shippingInstructionOne',
          fields: [
            {
              edges: [
                {
                  node: [...columnFields]
                }
              ]
            }
          ],
          variables: {
            siNumber: this.si
          }
        })

        const result = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`
              ${builder.query}
            `,
          variables: builder.variables
        })

        const shippingInstructionsResult = result.data.shippingInstructionOne

        this.shippingInstructions = shippingInstructionsResult.edges.map(
          e => e.node
        )
      } catch (e) {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: e.message,
          duration: 2500,
          hasIcon: true
        })
      }
      this.isLoading = false
    },
    handleVersionChange (selected) {
      this.viewingSi = selected
    },
    getStatus (si) {
      if (si.status === 'V') {
        if (si.billOfLading) {
          return 'Done'
        } else return 'Created'
      } else if (si.status === 'C') {
        return 'Canceled'
      } else if (si.status === 'U') {
        if (si.version > 0) {
          return 'Updated'
        } else return 'New'
      } else return null
    },
    getColor (si) {
      if (si.status === 'V') {
        if (si.billOfLading) {
          return 'success'
        } else return 'info'
      } else if (si.status === 'C') {
        return 'danger'
      } else if (si.status === 'U') {
        if (si.version > 0) {
          return 'warning'
        } else return 'orange'
      } else return null
    }
  }
}
</script>
