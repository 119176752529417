<template>
  <div class="section">
    <div class="container">
      <div class="level">
        <Header class="level-left" v-bind="$route.meta" />
        <b-button
          tag="router-link"
          :to="{ name: 'closure-templates', params: { collect_group: this.$route.params.collect_group } }"
          class="left-right"
          type="is-info"
          icon-left="arrow-left"
        >
          {{ $t('global.back') }}
        </b-button>
      </div>
      <div class="columns">
        <div class="column">
          <form @submit.prevent="submit">
            <div class="mb-5">
              <b-field :label="$t('pages.closure_schedule.mail_templates.template_form.name_field')">
                <b-input v-model="templateName" placeholder="Template Name" />
              </b-field>
            </div>
            <div class="mb-5">
              <b-field :label="$t('pages.closure_schedule.mail_templates.template_form.subject_field')">
                <b-input v-model="templateSubject" />
              </b-field>
            </div>
            <div class="mb-5">
              <b-field :label="$t('pages.closure_schedule.mail_templates.template_form.sender_field')">
                <b-input v-model="templateSender" type="email" />
              </b-field>
            </div>
            <div class="mb-5">
              <b-field :label="$t('pages.closure_schedule.mail_templates.template_form.content_field')">
                <vue-editor v-model="templateContent" />
              </b-field>
            </div>
            <div class="mb-5">
              <b-field :label="$t('pages.closure_schedule.mail_templates.template_form.signature_field')">
                <vue-editor v-model="templateSignature" />
              </b-field>
            </div>
            <div class="mb-5">
              <b-button type="is-primary" icon-left="play" native-type="submit">
                {{ $t('global.submit') }}
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import enduserClosureMail from '@/services/v3/enduserClosureMail'

export default {
  name: 'ClosureTemplates',
  components: {
    VueEditor
  },
  data: function () {
    return {
      templateId: null,
      templateName: 'New Template',
      templateSubject: 'Subject',
      templateSender: 'no-reply@fr-gps.com',
      templateContent: '',
      templateSignature: ''
    }
  },
  mounted: async function () {
    if (this.$route.params.template_id !== 'new') {
      const templateData = await enduserClosureMail.getEdit(this.$route.params.template_id)
      this.templateId = templateData.id
      this.templateName = templateData.templateName
      this.templateSubject = templateData.subject
      this.templateSender = templateData.sender
      this.templateContent = templateData.html
      this.templateSignature = templateData.signature
    }
  },
  methods: {
    async submit () {
      try {
        if (this.$route.params.template_id !== 'new') {
          await enduserClosureMail.update(
            {
              templateName: this.templateName,
              content: this.templateContent,
              subject: this.templateSubject,
              collectGroup: this.$route.params.collect_group,
              sender: this.templateSender,
              id: this.templateId,
              signature: this.templateSignature
            }
          )
        } else {
          await enduserClosureMail.insert(
            {
              templateName: this.templateName,
              content: this.templateContent,
              subject: this.templateSubject,
              collectGroup: this.$route.params.collect_group,
              sender: this.templateSender,
              signature: this.templateSignature
            }
          )
        }
        this.$router.push({ name: 'closure-templates' })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
      }
    }
  }
}

</script>
