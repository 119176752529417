<template>
  <div class="section">
    <div class="container">
      <div class="level">
        <Header class="level-left" v-bind="$route.meta" />
        <b-select v-model="selectedGroup" :placeholder="$t('pages.pickup_management.select')">
          <option v-for="grp in collectGroups" :key="grp.collectGroup" :value="grp">
            {{ grp.collectGroup + ' (' + grp.description + ')' }}
          </option>
          <option :value="{ collectGroup: 'ALL' }">
            All (Warning: Lot of data)
          </option>
        </b-select>
        <b-button
          tag="router-link"
          :to="{ name: 'closure-templates', params: { collect_group: selectedGroup ? selectedGroup.collectGroup : selectedGroup } }"
          class="left-right"
          type="is-success"
          icon-left="plus"
          :disabled="!selectedGroup"
        >
          {{ $t('pages.closure_schedule.mail_template_button') }}
        </b-button>
        <!-- <b-button
          class="left-right"
          type="is-warning"
          icon-left="clock"
          @click="sendCronJob"
        >
          {{ $t('pages.closure_schedule.cron_job_button') }}
        </b-button> -->
      </div>
      <div class="columns">
        <div class="column">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-balham"
            :row-data="endusers"
            :grid-options="gridOptions"
            :pagination="true"
            :pagination-auto-page-size="true"
            :side-bar="['columns']"
            :cache-overflow-size="0"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            :get-context-menu-items="getContextMenuItems"
            @grid-ready="gridReady"
          />
        </div>
      </div>
      <b-modal :active.sync="isEditingSchedule" scroll="keep" has-modal-card>
        <ScheduleForm :schedules="editableSchedule" :collect-group="selectedGroup ? selectedGroup.collectGroup : selectedGroup" @submit="submitUpdateUser" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
// import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import enduserClosureMail from '@/services/v3/enduserClosureMail'
import Collect from '@/services/v2/collect'
import ScheduleForm from './scheduleForm.vue'

export default {
  name: 'ClosureTemplates',
  components: {
    ScheduleForm
  },
  data: function () {
    return {
      modules: AllModules,
      selectedGroup: null,
      collectGroups: [],
      endusers: [],
      isEditingSchedule: false,
      editableSchedule: [],
      gridOptions: {
        cacheBlockSize: 20,
        paginationPageSize: 20,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        rowSelection: 'multiple',
        columnDefs: [
          { headerName: '@',
            field: 'mailAddress',
            width: 10
          },
          { headerName: this.$t('pages.closure_schedule.name_column_title'),
            field: 'name',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            sort: 'asc',
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.template_name_column_title'),
            field: 'templateName',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            sort: 'asc',
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.last_response_column_title'),
            field: 'lastResponse',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.reminder_status_column_title'),
            field: 'reminderStatus',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.next_reminder_column_title'),
            field: 'nextReminder',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_1_column_title'),
            field: 'mail1',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_2_column_title'),
            field: 'mail2',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          }
        ]
      }
    }
  },
  watch: {
    selectedGroup: async function (selectedGroup) {
      try {
        enduserClosureMail.getSchedule(selectedGroup.collectGroup).then((value) => {
          this.endusers = []
          value.forEach(element => {
            this.endusers.push(
              {
                id: element.id,
                name: element.name,
                code: element.code,
                mail1: element.mail1 ? new Date(element.mail1).toLocaleDateString() : element.mail1,
                mail2: element.mail2 ? new Date(element.mail2).toLocaleDateString() : element.mail2,
                nextReminder: element.nextReminder ? new Date(element.nextReminder).toLocaleDateString() : element.nextReminder,
                reminderStatus: element.reminderStatus,
                lastResponse: element.lastResponse ? new Date(element.lastResponse).toLocaleDateString() : element.lastResponse,
                template: element.template,
                templateName: element.templateName,
                mailAddress: element.mailAddress ? '✔' : '✗'
              }
            )
          })
        })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
      }
    }
  },
  mounted: async function () {
    const result = await Collect.getGroups()
    // sort the result into ascending order
    result.sort((a, b) => {
      const ga = a.collectGroup.toLowerCase()
      const gb = b.collectGroup.toLowerCase()

      if (ga < gb) {
        return -1
      }

      if (ga > gb) {
        return 1
      }

      return 0
    })
    this.collectGroups = result
    if (this.$route.params.collect_group) {
      this.selectedGroup = this.collectGroups.find(item => item.collectGroup === this.$route.params.collect_group)
    }
  },
  methods: {
    gridReady: function (params) {
    },
    async showEditForm (user) {
      const selectedRows = await this.gridOptions.api.getSelectedRows()
      this.editableSchedule = await selectedRows && await selectedRows.length > 1 ? selectedRows : [user]
      this.isEditingSchedule = true
    },
    sendMail (user) {
      if (user.mailAddress === '✗') {
        this.$buefy.toast.open({ message: this.$t('pages.closure_schedule.no_mail_error'), duration: 5000, type: 'is-danger' })
      } else {
        this.$buefy.toast.open({ message: this.$t('pages.closure_schedule.mail_sent'), duration: 3000, type: 'is-success' })
        enduserClosureMail.sendMail(user)
      }
    },
    sendCronJob () {
      enduserClosureMail.sendMails()
    },
    submitUpdateUser (newUser) {
      this.editableSchedule.forEach((element, index) => {
        this.editableSchedule[index].id = element.id
        this.editableSchedule[index].mail1 = newUser.mail1
        this.editableSchedule[index].mail2 = newUser.mail2
        this.editableSchedule[index].template = newUser.template
      })
      enduserClosureMail.updateSchedule(this.editableSchedule)
      this.isEditingSchedule = false
      try {
        enduserClosureMail.getSchedule(this.selectedGroup.collectGroup).then((value) => {
          this.endusers = []
          value.forEach(element => {
            this.endusers.push(
              {
                id: element.id,
                name: element.name,
                code: element.code,
                mail1: element.mail1 ? new Date(element.mail1).toLocaleDateString() : element.mail1,
                mail2: element.mail2 ? new Date(element.mail2).toLocaleDateString() : element.mail2,
                nextReminder: element.nextReminder ? new Date(element.nextReminder).toLocaleDateString() : element.nextReminder,
                reminderStatus: element.reminderStatus,
                lastResponse: element.lastResponse ? new Date(element.lastResponse).toLocaleDateString() : element.lastResponse,
                template: element.template,
                templateName: element.templateName,
                mailAddress: element.mailAddress ? '✔' : '✗'
              }
            )
          })
        })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
      }
    },
    getContextMenuItems (params) {
      const selectedUser = params.node.data
      let customMenu = []
      const genericMenu = [
        'separator',
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      customMenu.push({
        name: this.$t('pages.closure_schedule.context_menu.edit'),
        action: () => {
          this.showEditForm(selectedUser)
        },
        icon: '<i class="fas fa-fw fa-edit" ></i>'
      })
      customMenu.push({
        name: this.$t('pages.closure_schedule.context_menu.send_mail'),
        action: () => {
          this.sendMail(selectedUser)
        },
        icon: '<i class="fas fa-fw fa-paper-plane" ></i>'
      })
      const result = [...customMenu, ...genericMenu]
      return result
    }
  }
}

</script>
