<template>
  <nav class="panel" style="width: 100%">
    <p class="panel-heading filters-heading">
      <span class="clear-filters" @click="clearFilters">Clear</span>
    </p>
    <div class="panel-block">
      {{ filteredMarkers.length }} markers
    </div>
    <div class="panel-block">
      <div class="control">
        <label class="label">Types</label>
        <b-field v-for="type in types" :key="type">
          <b-checkbox v-model="typeCheckboxGroup" :native-value="type">
            {{ type }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <div class="panel-block">
      <div class="control">
        <b-field label="Name">
          <b-autocomplete
            v-model="name"
            placeholder="e.g. partner name"
            :data="filteredMarkers"
            icon="search"
            field="name"
            @select="handleMarkerSelection"
          >
            <template slot="empty">
              No results found
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
    <!-- <div class="panel-block">
      <div class="control">
        <b-field label="Stock">
          <b-slider v-model="value" size="is-medium"/>
        </b-field>
      </div>
    </div> -->
  </nav>
</template>

<script>
export default {
  props: {
    markers: { type: Array, required: true }
  },
  data: function () {
    return {
      // value: 70,
      name: '',
      typeCheckboxGroup: [],
      selectedMarker: null
    }
  },
  computed: {
    categories: function () {
      return [...new Set(this.markers.map(e => e.regionCategory).filter(e => e).sort())]
    },
    types: function () {
      return [...new Set(this.markers.map(e => e.type).filter(e => e).sort())]
    },
    // regions: function () {
    //   return [...new Set(this.markers.map(e => e.region).filter(e => e).sort())]
    // },
    filteredMarkers () {
      let { name, markers, typeCheckboxGroup } = this

      return markers.filter(row => {
        const typeMatches = typeCheckboxGroup.includes(row.type)
        const nameMatches =
          name.length > 2
            ? row.name.toLowerCase().includes(name.toLowerCase()) ||
              row.enduserCode?.includes(name)
            : true

        return typeMatches && nameMatches
      })
    }
  },
  watch: {
    types: function (val) {
      this.typeCheckboxGroup = val
    }
  },
  methods: {
    clearFilters () {
      this.name = ''
      this.typeCheckboxGroup = this.types
    },
    handleMarkerSelection (marker) {
      this.selectedMarker = marker
      this.$emit('marker-selected', marker)// Notify parent to move map focus
    }
  }
}
</script>

<style>
.clear-filters {
  cursor: pointer;
}
.filters-heading {
  display: flex;
  justify-content: flex-end;
}

</style>
