<template>
  <div>
    <b-loading :active.sync="loading" is-full-page />
    <b-modal :active.sync="showCollectCreation" scroll="keep" has-modal-card full-screen>
      <collectCreation :endusers="selectedEndusers" :selected-group="selectedGroup" @close="closeCollectCreation" />
    </b-modal>
    <div class="actions">
      <b-field label="Left panel size">
        <b-slider v-model="customWidth" size="is-small" :max="10" :step="0.5" />
      </b-field>
    </div>
    <div class="d-flex">
      <div id="left-panel" class="left-box custom-container" :style="customWidth > 0 ?'width: ' + customWidth * 100 + 'px' : 'display: none;' ">
        <slot />
        <div class="left-box" style="width: 100%">
          <div class="panel" style="width: 100%">
            <div class="panel-heading">
              <span>
                Update location
              </span>
            </div>
            <div v-if="updatingCoords" class="panel-block">
              <span>
                click on the map to choose the new location
              </span>
            </div>
            <div class="left-box panel-block">
              <div
                v-if="!updatingCoords"
              >
                <button
                  :disabled="!markers.find(m => m.selected)"
                  class="button is-info is-medium"
                  @click="switchUpdatingCoords"
                >
                  <span> Update</span>
                </button>
              </div>
              <div
                v-else
                class="left-box"
              >
                <button
                  class="button is-warning is-medium"
                  @click="switchUpdatingCoords"
                >
                  <span>Cancel</span>
                </button>
                <button
                  style="margin-top: 10px;"
                  :disabled="!markers.find(m => m.newMarker) || !markers.find(m => m.selected)"
                  class="button is-primary is-medium"
                  @click="updateCoords"
                >
                  <span> Update</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="noCoords" class="panel" style="width: 100%">
            <div class="panel-heading">
              Unplaced
            </div>
            <div class="panel-block">
              <multiselect
                v-model="typeToPlace"
                :options="[{id:0, title: 'customer'}, {id:1, title: 'enduser'}]"
                :multiple="false"
                :close-on-select="true"
                label="title"
                track-by="title"
              />
            </div>
            <div class="panel-block">
              <multiselect
                v-if="typeToPlace"
                v-model="selectedNoCoords"
                :options="getNoCoordsOptions()"
                :multiple="false"
                :close-on-select="true"
                :custom-label="n => {return n.id + ' - ' + n.name}"
                track-by="name"
              />
            </div>
            <div v-if="selectedNoCoords" class="panel-blok" style="text-align: left;">
              <span>
                <span class="bold">
                  Address:
                </span>
                {{ selectedNoCoords.address }}
              </span> <br>
              <span>
                <span class="bold">
                  City:
                </span>
                {{ selectedNoCoords.city }} - {{ selectedNoCoords.postalCode }}
              </span> <br>
              <span>
                <span class="bold">
                  Country:
                </span>
                {{ selectedNoCoords.country }}
              </span>
            </div>
            <div v-if="selectedNoCoords" class="panel-block left-box">
              Now click on the map to place it
            </div>
            <div class="panel-block left-box">
              <button
                style="margin-top: 10px;"
                :disabled="!markers.find(m => m.newMarker) || !selectedNoCoords"
                class="button is-info is-medium"
                @click="createCoords"
              >
                <span> Create </span>
              </button>
            </div>
          </div>
          <div v-if="mapType === 'pickup'" class="panel" style="width: 100%">
            <div class="panel-heading">
              <span>
                Filters
              </span>
            </div>
            <b-button type="is-warning" class="is-small" @click="() => {filteredMarkers = markers}">
              Reset
            </b-button>
            <div class="panel-block">
              <multiselect
                v-model="filterType"
                :options="['Quantity', 'Materials']"
                :multiple="false"
                :close-on-select="true"
              />
            </div>
            <div v-if="filterType === 'Quantity'" class="left-box panel-block">
              <b-field :label="`Any quantity ≥ ${quantityFilter ? quantityFilter : ''}`">
                <b-input v-model="quantityFilter" placeholder="Enter a minimum quantity" />
              </b-field>
            </div>
            <div v-if="filterType === 'Materials'">
              <div>
                <b-radio
                  v-model="matFilterComparisonType"
                  name="name"
                  native-value="And"
                >
                  And
                </b-radio>
                <b-radio
                  v-model="matFilterComparisonType"
                  name="name"
                  native-value="Or"
                >
                  Or
                </b-radio>
              </div>
              <div class="panel-block d-flex-space-evenly">
                <span>
                  <b-button type="is-primary" class="is-small" @click="() => {matFilters.push({mat: '', qty: ''})}">Add material filter</b-button>
                </span>
              </div>
              <div v-for="(f, index) in matFilters" :key="index">
                <div class="separator" />
                <div class="left-box panel-block">
                  <div
                    @click="removeFilter(index)"
                  >
                    <b-icon
                      icon="times"
                      size="is-small"
                      type="is-danger"
                      class="pull-right custom-quit-cross"
                    />
                  </div>
                  <multiselect
                    v-model="f.mat"
                    :options="materials"
                    :multiple="false"
                    :close-on-select="true"
                    :custom-label="n => n.MATNR + ' - ' + n.MAKTX"
                    track-by="MATNR"
                  />
                  <b-field :label="`${f.mat ? f.mat.MATNR + ' ≥ ' : ''} ${f.qty ? f.qty : ''}`">
                    <b-input v-model="f.qty" placeholder="Enter a minimum quantity" />
                  </b-field>
                </div>
              </div>
              <b-button v-if="matFilters.length > 0" type="is-info" @click="submitMatFilters">
                Search
              </b-button>
            </div>
          </div>
          <div v-if="mapType === 'pickup'" class="panel" style="width: 100%">
            <div class="panel-heading">
              <span>
                Grouped collect
              </span>
            </div>
            <div class="left-box panel-block">
              <b-button v-if="!makingGroupedCollect" type="is-info" @click="() => {makingGroupedCollect = true}">
                <span>
                  Make grouped collect
                </span>
              </b-button>
              <b-button v-else type="is-warning" @click="cancelMakingGroupedCollect">
                <span>
                  Cancel
                </span>
              </b-button>
            </div>
            <div v-if="makingGroupedCollect" class="left-box panel-block">
              <div>
                Please select desired endusers
              </div>
              <div>
                Selected endusers :
              </div> <br>
              <div v-for="s in filteredMarkers.filter(f => f.selected)" :key="s.id">
                <span>
                  °{{ s.enduserCode }} - {{ s.enduserName }}
                </span>
              </div>
              <b-button type="is-primary" @click="submitGroupedCollect">
                <span>
                  Submit
                </span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div id="gps-map" style="height: 93vh; width: 100%">
        <l-map
          ref="mapRef"
          style="z-index: 10"
          :world-copy-jump="true"
          :zoom="zoom"
          :min-zoom="3"
          :center="center"
          :bounds="computedBounds"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
          @ready="mapReady"
          @click="mapClick"
        >
          <l-tile-layer :url="url" />

          <l-control position="bottomright">
            <map-legend id="legend" class="box is-hidden-touch" :map-type="mapType" />
          </l-control>
          <l-marker-cluster v-if="filteredMarkers" :options="clusterOptions">
            <l-marker
              v-for="marker in filteredMarkers"
              :key="marker.mapMarker.id"
              :lat-lng="marker.mapMarker.getLatLng()"
              :icon="marker.mapMarker.getIcon()"
              @mouseover="marker.hoverEvent"
              @click="selectMarker"
            >
              <l-tooltip :options="{ sticky: true }">
                <b>{{ marker.name }}</b>
                <br>{{ marker.city }}
                <br>{{ marker.country }}
                <div v-if="marker.lastInventory && marker.lastInventory.length > 0">
                  <span>-------</span><br>
                  <b>Materials declaration :</b> <br>
                  <span v-if="marker.lastInventory && marker.lastInventory.length >0 && marker.lastInventory[0].date">Made on {{ $dayjs(marker.lastInventory[0].date).local().format('DD/MM/YYYY') }}</span>
                  <div v-for="q in marker.lastInventory" :key="q.material">
                    {{ q.material }} : {{ q.inventory }}
                  </div>
                </div>
                <div v-if="marker.lastCollect && marker.lastCollect.length">
                  <span>-------</span><br>
                  <b>Last collect :</b> <br>
                  <span v-if="marker.lastCollect[0].loadingDate">Made on {{ $dayjs(marker.lastCollect[0].loadingDate).local().format('DD/MM/YYYY') }}</span>
                  <div v-for="(q, index) in marker.lastCollect" :key="index">
                    {{ q.mat }} : {{ q.qty }}
                  </div>
                </div>
              </l-tooltip>
            </l-marker>
          </l-marker-cluster>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>

import { GoogleProvider, GeoSearchControl } from 'leaflet-geosearch'
import L from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import MapLegend from '@/components/map/map-legend'
import { LMap, LTileLayer, LMarker, LTooltip, LControl } from 'vue2-leaflet'

import { markerTypes } from '@/components/map/mTypes'
import { pickupMTypes } from '@/components/map/pickupMTypes'
import mapMethods from '@/mixins/mapMethods'
import Map from '@/services/v2/map'
import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
import collectCreation from '@/views/EnduserDeclarationAdmin/collectCreation'

export default {
  name: 'LeafletMap',
  components: {
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    MapLegend,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControl,
    collectCreation
  },
  mixins: [mapMethods],
  props: {
    name: {
      type: String,
      default: ''
    },
    setHoverItem: {
      type: Function,
      default: () => { }
    },
    setDestination: {
      type: Function,
      default: () => { }
    },
    markers: {
      type: Array,
      default: Array
    },
    noCoords: {
      type: Array,
      default: null
    },
    mapType: {
      type: String,
      default: 'default'
    },
    selectedGroup: {
      type: String,
      default: ''
    },
    selectedMarker: { type: Object, default: null }
  },
  data () {
    return {
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGhvbWFzLW1vbGluYS1ncHMiLCJhIjoiY2puMWlhY2NqMGJydzNrbDhjejBnMjhkNyJ9.V2wNLvFQw1OnOqTrWN7xjA',
      zoom: 3,
      center: [0, 0],
      hoveredMarker: null,
      markerTypes: Array.from(markerTypes),
      pickupMTypes: Array.from(pickupMTypes),
      // selectedMarker: null,
      updatingCoords: false,
      clusterOptions: {
        animate: false,
        removeOutsideVisibleBounds: true,
        chunkedLoading: true,
        disableClusteringAtZoom: 8,
        spiderfyOnMaxZoom: false
        // iconCreateFunction: (cluster) => divIcon({ html: '<div><span>' + cluster.getChildCount() + '</span></div>', className: 'marker-cluster', iconSize: new Point(40, 40) })
      },
      geosearchOptions: { // Important part Here
        provider: new GoogleProvider({
          params: {
            key: 'AIzaSyCabL-Er3SaPVcNCld1orTRSJuyyYecOso'
          }
        })
      },
      selectedNoCoords: null,
      customWidth: 2.50,
      typeToPlace: null,
      loading: false,
      quantityFilter: null,
      filteredMarkers: [],
      matFilters: [],
      materials: [],
      filterType: null,
      matFilterComparisonType: 'And',
      makingGroupedCollect: false,
      showCollectCreation: false,
      selectedEndusers: null
    }
  },
  computed: {
    computedBounds () {
      return (this.markers.some(e => e.selected) || !this.markers.length) ? null : L.latLngBounds(this.markers.map(e => e.mapMarker.getLatLng()))
    }
  },
  watch: {
    typeToPlace: function () {
      this.selectedNoCoords = null
    },
    quantityFilter: function () {
      if (this.quantityFilter) {
        this.filteredMarkers = this.markers.filter(m => m.lastInventory && m.lastInventory.filter(q => q.inventory >= this.quantityFilter).length > 0)
      } else {
        this.filteredMarkers = this.markers
      }
    },
    markers: function () {
      this.filteredMarkers = this.markers
    },
    selectedMarker (newMarker) {
      this.$refs.mapRef.mapObject.setView([+newMarker.lat, +newMarker.lng], 14)
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.getMaterials()
      this.filteredMarkers = this.markers
      const searchControl = new GeoSearchControl({
        provider: new GoogleProvider({
          params: {
            key: 'AIzaSyCabL-Er3SaPVcNCld1orTRSJuyyYecOso'
          }
        })
      })
      if (this.$refs.mapRef) {
        this.$refs.mapRef.mapObject.addControl(searchControl)
      }
    })
  },
  methods: {
    mapReady () {
      // this.selectedMarker = this.markers.find(e => !!e.selected)
      // if (this.selectedMarker) {
      //   this.zoom = 8
      // }
    },
    zoomUpdated (zoom) {
      this.zoom = zoom
    },
    centerUpdated (center) {
      if (!this.selectedMarker) { this.center = center } else { this.center = [this.selectedMarker.lat, this.selectedMarker.lng] }
    },
    boundsUpdated (bounds) {
      this.bounds = bounds
    },
    selectMarker (marker) {
      const actuallySelected = this.markers.find(m => m.selected)
      const selected = this.markers.find(m => { return parseFloat(m.lat) === parseFloat(marker.latlng.lat) && parseFloat(m.lng) === parseFloat(marker.latlng.lng) })
      if (selected) {
        if (actuallySelected && actuallySelected !== selected && !selected.newMarker && !this.makingGroupedCollect) {
          actuallySelected.selected = false
        }
        if (selected.newMarker) {
          this.markers.splice(this.markers.indexOf(selected), 1)
        } else {
          selected.selected = !selected.selected
        }
        if (actuallySelected) {
          this.refreshMapMarker(actuallySelected)
        }
        this.refreshMapMarker(selected)
      }
    },
    mapClick (e) {
      if (this.updatingCoords || this.selectedNoCoords) {
        this.addMarker(e)
      }
    },
    switchUpdatingCoords () {
      if (this.updatingCoords) {
        const newMarker = this.markers.find(m => m.newMarker)
        if (newMarker) {
          this.markers.splice(this.markers.indexOf(newMarker))
        }
      }
      this.updatingCoords = !this.updatingCoords
    },
    addMarker (e) {
      const exist = this.markers.find(m => m.newMarker)
      if (exist) {
        this.markers.splice(this.markers.indexOf(exist), 1)
      }
      this.markers.push({
        hoverEvent: () => {},
        newMarker: true,
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        name: 'New marker',
        mapMarker: new L.Marker(
          [e.latlng.lat, e.latlng.lng],
          {
            icon: new L.Icon({
              iconUrl: 'https://cdn.mapmarker.io/api/v1/pin?size=50&icon=fa-wrench&background=A000B0',
              shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
              iconSize: e.selected ? [62, 60] : [42, 40],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
              shadowAnchor: [5, 43] // the same for the shadow
            })
          })
      })
    },
    async updateCoords () {
      this.loading = true
      try {
        const newMarker = this.markers.find(m => m.newMarker)

        if (!newMarker) {
          throw Error('No new location selected')
        }
        const selected = this.markers.find(m => m.selected)
        await Map.updateCoords({
          enduserCode: selected.id || selected.enduserCode,
          lat: newMarker.lat + '',
          lng: newMarker.lng + ''
        })
        this.switchUpdatingCoords()
        this.$buefy.toast.open({ message: 'Success' + '<br> You have to refresh the page to see the new location', duration: 8000, type: 'is-primary' })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
      }
      this.loading = false
    },
    createCoords: async function () {
      this.loading = true
      try {
        const newMarker = this.markers.find(m => m.newMarker)

        if (!newMarker) {
          throw Error('No new location selected')
        }
        await Map.updateCoords({
          enduserCode: this.selectedNoCoords.id,
          lat: newMarker.lat + '',
          lng: newMarker.lng + ''
        })
        this.$buefy.toast.open({ message: 'Success' + '<br> You have to refresh the page to see the new location', duration: 8000, type: 'is-primary' })
        this.loading = false
      } catch (error) {
        this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
        this.loading = false
      }
    },
    getNoCoordsOptions: function () {
      switch (this.typeToPlace.title) {
        case 'customer':
          return this.noCoords[0]
        case 'enduser':
          return this.noCoords[1]

        default:
          return [{ title: 'error' }]
      }
    },
    getMaterials: async function () {
      this.materials = await EnduserDeclarationAdmin.getMaterials()
    },
    removeFilter: function (index) {
      this.matFilters.splice(index, 1)
    },
    submitMatFilters: function () {
      try {
        this.filteredMarkers = this.markers.filter(m => {
          if (m.lastInventory) {
            const filtered = this.matFilters.filter(f => {
              const qf = m.lastInventory.filter(q => {
                if (f.mat.MATNR === q.material && q.inventory >= f.qty) {
                  return q
                }
              })
              if (qf.length > 0) {
                return qf
              }
            })

            if (this.matFilterComparisonType === 'And') {
              return filtered.length === this.matFilters.length // AND
            } else if (this.matFilterComparisonType === 'Or') {
              return filtered.length > 0 // OR
            } else {
              throw Error('Comparison operator unknown')
            }
          }
        })
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
      }
    },
    cancelMakingGroupedCollect: function () {
      this.makingGroupedCollect = false
      this.filteredMarkers.forEach(m => {
        if (m.selected) {
          m.selected = false
          this.refreshMapMarker(m)
        }
      })
    },
    submitGroupedCollect: function () {
      this.selectedEndusers = this.filteredMarkers.filter(f => f.selected)
      this.showCollectCreation = true
    },
    closeCollectCreation: function () {
      this.showCollectCreation = false
    }
  }

}
</script>
<style scoped lang="scss">
  .marker-cluster-gps {
    background-color: rgba(241, 90, 90, 0.3);
    div {
      background-color: rgba(241, 90, 90, 0.6);
    }
  }
  .d-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .left-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .custom-container {
    padding: 10px;
  }

  .bold {
    font-weight: bold;
  }

  .actions {
    // width: 500px;
    display: flex;
    justify-content: center;
  }

  .separator {
    border: dashed 1px rgba($color: #000000, $alpha: 0.2);
    height: 1px;
  }

  .custom-quit-cross :hover {
    cursor: pointer;
  }

  #left-panel{
    overflow: auto;
    height: 93vh;
  }
</style>
