<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <!-- <div style="display: flex; justify-content: center; margin: 2rem; "> -->
    <pickupTour id="pickupTour" ref="pickupTour" @tourStoped="stopTour" />
    <div id="pickupTourContainer" />
    <b-modal :active.sync="showStats" scroll="keep" has-modal-card full-screen>
      <stats :mails="selectedMails" :range-dates="rangeDates" @close="closeStatsScreen" />
    </b-modal>
    <b-modal :active.sync="showMailTemplates" scroll="keep" has-modal-card full-screen>
      <mailTemplates :selected-group="selectedGroup" @nextTourStep="nextTourStep" @close="closeMailTemplates" />
    </b-modal>
    <b-modal :active.sync="showSignature" scroll="keep" has-modal-card full-screen>
      <signature :selected-group="selectedGroup" @close="closeSignatureScreen" />
    </b-modal>
    <div v-if="!showDeclaration && !showStats && !showSignature && !showMailTemplates">
      <section>
        <div class="pickup-actions-container">
          <span>
            <b-tooltip
              :label="$t('pages.pickup_management.clear_filters')"
              position="is-top"
            >
              <b-button icon-left="filter" type="is-danger" rounded @click="clearFilters" />
            </b-tooltip>
          </span>
          <span>
            <b-tooltip
              id="pickupTourBtn"
              :label="$t('pages.pickup_management.page_tutorial')"
              position="is-top"
            >
              <b-button icon-left="question" type="is-info" rounded @click="startTour" />
            </b-tooltip>
          </span>
        </div>
        <div class="columns">
          <div class="column is-one-fifth">
            <b-field :label="$t('pages.pickup_management.pickup_management.range_dates_label')">
              <b-datepicker
                v-model="rangeDates"
                placeholder="Click to select..."
                range
              />
            </b-field>
          </div>
          <div v-if="selectedGroup" class="custom-container buttons column">
            <b-button
              icon-left="eye-slash"
              class="button"
              :class="filteredEndusers === computedEndusers ? 'is-purple' : 'is-danger'"
              :disabled="loading"
              @click="showNotSeenDeclarations"
            >
              {{ $t('pages.pickup_management.pickup_management.show_not_seen_declarations') }}
              <span class="count">
                {{ notSeenDeclaration.length }}
              </span>
            </b-button>
            <b-button icon-left="map" class="button is-primary" :disabled="loading" @click="() => {showMap = true}">
              {{ $t('pages.pickup_management.pickup_management.open_map') }}
            </b-button>
            <b-button
              id="pickup-step-4"
              icon-left="envelope-square"
              class="button is-link"
              :disabled="loading"
              @click="()=> {showMailTemplates = true; nextTourStep('createMailTemplates')}"
            >
              {{ $t('pages.pickup_management.pickup_management.manage_mail_templates') }}
            </b-button>
            <b-button
              id="pickup-step-17"
              icon-left="signature"
              class="button is-info"
              :disabled="loading"
              @click="()=> {showSignature = true}"
            >
              {{ $t('pages.pickup_management.pickup_management.edit_signature') }}
            </b-button>
            <b-button
              id="pickup-step-17"
              icon-left="hand-pointer"
              class="button"
              :class="rowSelection ? 'is-purple' : 'is-danger'"
              :disabled="loading"
              @click="switchRowSelection"
            >
              {{ $t('pages.pickup_management.pickup_management.row_selection') + ' : ' }}
              {{ rowSelection ? $t('pages.pickup_management.pickup_management.row') : $t('pages.pickup_management.pickup_management.cell') }}
            </b-button>
            <b-button
              :loading="loading"
              icon-left="sync"
              class="button is-warning"
              :disabled="loading"
              @click="() => refresh()"
            >
              {{ $t('pages.pickup_management.pickup_management.refresh') }}
            </b-button>
          </div>
        </div>

        <div class="caption">
          <span class="caption-title">
            {{ $t('pages.pickup_management.pickup_management.caption') }}
          </span>
          <br>
          <i class="fas fa-exclamation-circle has-text-warning" /> : {{ $t('pages.pickup_management.pickup_management.declaration_with_comments') }}
          <br>
          <i class="fas fa-info has-text-info" /> : {{ $t('pages.pickup_management.pickup_management.enduser_with_comments') }}
        </div>
        <ag-grid-vue
          style="width: 100%; height: 700px; z-index: 1;"
          class="ag-theme-balham"
          :row-data="filteredEndusers"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          :row-selection="rowSelection"
          :enable-range-selection="true"
          @grid-ready="gridReady"
        />
      </section>
    </div>
    <div v-else-if="showDeclaration" class="section">
      <span class="buttons">
        <button
          class="button is-warning is-outlined is-small"
          @click="() => {
            showDeclaration = false;
            loadData()
          }"
        >
          <b-icon icon="chevron-left" />
          <span>{{ $t('global.back') }}</span>
        </button>
      </span>
      <div class="card-content">
        <div class="has-text-centered">
          <h2 class="title">
            {{ selectedMailDeclaration.enduserName }}
          </h2>
          <i class="title">
            {{ $dayjs(selectedMailDeclaration.date).local().format('DD-MM-YYYY HH:mm') }}
          </i>
          <br>
          <i>
            (DD-MM-YYYY HH:mm)
          </i>
        </div>
        <div class="mb">
          <h2 class="title">
            {{ $t('pages.pickup_management.pickup_management.declaration.identity') }}
          </h2>
          <div class="card">
            <span v-if="selectedMailDeclaration.identity" class="commentsSection">
              {{ selectedMailDeclaration.identity }}
            </span>
            <span v-else class="commentsSection">
              <i>{{ $t('global.no_data') }}</i>
            </span>
          </div>
        </div>
        <div class="mb">
          <h2 class="title">
            {{ $t('pages.pickup_management.pickup_management.declaration.comments') }}
          </h2>
          <div class="card">
            <span v-if="selectedMailDeclaration.comments" class="commentsSection">
              {{ selectedMailDeclaration.comments }}
            </span>
            <span v-else class="commentsSection">
              <i>{{ $t('pages.pickup_management.pickup_management.declaration.no_comments') }}</i>
            </span>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div v-for="dc of declarationQuantities" :key="dc.material" class="column is-one-third">
          <div class="card has-text-centered">
            <div class="card-header">
              <span class="card-header-title">
                {{ dc.material }}
              </span>
            </div>
            <div class="card-content">
              <h2 class="title" :class="dc.quantity > 0 ? 'has-text-primary' : null">
                {{ dc.quantity }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="showMap" scroll="clip" has-modal-card full-screen>
      <Map
        :endusers="computedEndusers"
        :enduser-code="selectedEnduserCode"
        :selected-group="selectedGroup"
        :collects="collects"
      />
    </b-modal>

    <b-modal :active.sync="settingMailTemplate" scroll="keep" has-modal-card>
      <template>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ $t('pages.pickup_management.pickup_management.set_mail_template') }}
            </p>
          </header>
          <section
            id="pickup-step-14"
            class="modal-card-body"
          >
            <b-select
              v-if="selectedGroup && mailTemplates"
              v-model="selectedMailTemplate"
              :placeholder="$t('pages.pickup_management.pickup_management.select_template')"
            >
              <option
                v-for="template in mailTemplates.filter(m => m.collectGroup === selectedGroup.collectGroup)"
                :key="template.id"
                :value="template"
              >
                {{ template.templateName }}
              </option>
              <option value="">
                {{ $t('pages.pickup_management.pickup_management.none') }}
              </option>
            </b-select>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-fullwidth" @click="() => {settingMailTemplate = false; nextTourStep('closeSettingMailTemplate')}">
              {{ $t('global.cancel') }}
            </button>
            <button id="pickup-step-15" :disabled="selectedMailTemplate === null" class="button is-info is-fullwidth" @click="setMailTemplate">
              {{ $t('global.submit') }}
            </button>
          </footer>
        </div>
      </template>
    </b-modal>

    <b-modal v-if="selectedEnduser" :active.sync="settingEnduserComments" scroll="keep" has-modal-card>
      <template>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ $t('pages.pickup_management.pickup_management.set_enduser_comments') }}
            </p>
          </header>
          <section
            id="pickup-step-14"
            class="modal-card-body"
          >
            <b-input v-model="selectedEnduser.infos.comments" type="textarea" />
          </section>
          <footer class="modal-card-foot">
            <button class="button is-fullwidth" @click="() => {settingEnduserComments = false; selectedEnduser.infos.comments = tmp; selectedEnduser = null}">
              {{ $t('global.cancel') }}
            </button>
            <button id="pickup-step-15" class="button is-info is-fullwidth" @click="setEnduserComments">
              {{ $t('global.submit') }}
            </button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
// import Calendar from './calendar'
import Stats from './stats'
import Signature from './signature'
import MailTemplates from './mailTemplates'
import pickupTour from './pickupTour'

import Map from './map'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { mapActions } from 'vuex'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'

export default {
  components: {
    Map,
    Stats,
    Signature,
    MailTemplates,
    pickupTour
  },
  mixins: [sortAndFilterMixins],
  props: {
    selectedGroup: {
      type: Object,
      default: null
    },
    endusers: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      mails: [],
      declarationQuantities: [],
      selectedMailDeclaration: null,
      showDeclaration: false,
      collectEndusers: [],
      mailTemplates: [],
      mailTemplatesMap: [],
      settingMailTemplate: false,
      selectedMailTemplate: null,
      selectedEnduser: null,
      selectedEnduserCode: null,
      map: null,
      marker: null,
      showMap: false,
      selectedEnduserCoords: null,
      showStats: false,
      showSignature: false,
      showMailTemplates: false,
      selectedMails: [],
      settingEnduserComments: null,
      endusersInfos: [],
      tmp: null,
      loading: false,
      collects: [],
      filteredEndusers: [],
      notSeenDeclaration: [],
      pickupData: {},
      gridEndusers: [],
      computedEndusers: [],
      rangeDatesDefault: [new Date(this.$dayjs().subtract(3, 'month').toString()), new Date(this.$dayjs().toString())],
      rangeDates: null,
      rowSelection: 'multiple',

      materials: [],
      modules: AllModules,

      gridOptions: {
        masterDetail: true,
        getContextMenuItems: this.mainActions,
        tooltipShowDelay: 0,
        enableBrowserTooltips: true,
        onFilterChanged: this.filterChanged,

        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true,
          tooltipValueGetter: params => {
            if (params.data && params.data.infos) {
              return params.data.infos.comments || null
            }
            return ''
          }
        },

        columnDefs: [
          {
            headerName: 'Status',

            children: [
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.last_declaration_seen'),
                colId: 'declarationSeen',
                width: 120,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                headerClass: 'pickup-step-20',

                valueGetter: (params) => {
                  if (params.data && params.data.lastMail && params.data && params.data.lastMail.seen != null) {
                    if (params.data.lastMail.seen) {
                      return 'Yes'
                    } else {
                      return 'No'
                    }
                  }
                  return ''
                },
                cellClassRules: {
                  'rag-green': function (params) { return params.value === 'Yes' },
                  'rag-blue': function (params) { return params.value === 'No' }
                }
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.mailing_process'),
                colId: 'mailingProcess',
                width: 120,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                headerClass: 'pickup-step-3',

                valueGetter: (params) => {
                  if (params.data.interval) {
                    return `${params.data.interval} days`
                  } else {
                    return ''
                  }
                }
                // cellClassRules: {
                //   'rag-green': function (params) { return params.value === 'Yes' },
                //   'rag-yellow': function (params) { return params.value === 'No' }
                // }
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.response_rate'),
                colId: 'declarationRatio',
                sort: 'desc',
                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                  let dA = nodeA?.data?.declarationRatio
                  let dB = nodeB?.data?.declarationRatio
                  if (!dA && nodeA?.data?.mails?.length === 0) {
                    if (nodeA?.data?.interval) {
                      dA = -2
                    } else {
                      dA = -1
                    }
                  }
                  if (!dB && nodeB?.data?.mails?.length === 0) {
                    if (nodeB?.data?.interval) {
                      dB = -2
                    } else {
                      dB = -1
                    }
                  }
                  return (dA ?? 0) > (dB ?? 0) ? 1 : -1
                },
                width: 120,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  if (params.data.mails?.length > 0) {
                    return params.data.declarationRatio + ' %'
                  }
                  if (params.data.interval) {
                    return 'New'
                  }
                  return ''
                },
                cellClassRules: {
                  'rag-green': function (params) {
                    const percentage = params.value.replace('%', '')
                    return percentage > 75
                  },
                  'rag-yellow': function (params) {
                    const percentage = params.value.replace('%', '')
                    return percentage > 35 && percentage <= 75
                  },
                  'rag-red': function (params) {
                    const percentage = params.value.replace('%', '')
                    return percentage !== '' && percentage >= 0 && percentage <= 35
                  },
                  'rag-blue': function (params) { return params.value === 'New' }
                }
              }
            ]
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.mail_template'),
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            colId: 'mailTemplate',
            headerClass: 'pickup-step-16',

            valueGetter: (param) => {
              if (param.data.mailTemplate) {
                return this.mailTemplates.find(mail => {
                  return mail.id.toString() === param.data.mailTemplate.toString()
                })?.templateName
              } else {
                return ''
              }
            }
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.country'),
            field: 'country',
            width: 160,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            cellRenderer: this.flagRender
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.enduser'),
            colId: 'enduser',
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            valueGetter: (data) => {
              return data.data.enduserCode + ' - ' + data.data.enduserName
            },
            cellRenderer: (params) => {
              if (params.data && params.data.infos && params.data.infos.comments) {
                return '<i class="fas fa-info has-text-info"></i> ' + params.value || ''
              } else return params.value || ''
            },
            width: 300
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.enduser_type'),
            field: 'enduserType',
            width: 120
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.contact'),
            children: [
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.email'),
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                field: 'emails'

              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.tel'),
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                field: 'enduserTel'

              }
            ]
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.last_mail'),
            children: [
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.reminder_sending_date'),
                colId: 'reminderSendingDate',
                sort: 'desc',
                comparator: this.dateSort,
                filter: 'agDateColumnFilter',

                filterParams: {
                  // provide comparator function

                },
                valueGetter: (params) => {
                  return params.data && params.data.lastMail ? this.$dayjs(params.data.lastMail.lastSendingDate).local().format('DD/MM/YYYY').toString() : ''
                },
                width: 150
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.reminders_count'),
                field: 'lastMail.relaunchCount',
                cellRenderer: 'agGroupCellRenderer',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                headerClass: 'pickup-step-18',

                cellClass: (params) => {
                  if (params.value) {
                    if (parseInt(params.value) < 2) {
                      return 'rag-green'
                    } else if (parseInt(params.value) === 2) {
                      return 'rag-yellow'
                    } else {
                      return 'rag-red'
                    }
                  } else {
                    return null
                  }
                },
                width: 100
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.days_since_first_reminder'),
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                colId: 'daysUntilFirstReminder',
                valueGetter: (param) => {
                  if (param.data.lastMail) {
                    return Math.trunc(this.$dayjs().diff(this.$dayjs(param.data.lastMail.firstSendingDate), 'day'))
                  }
                },

                width: 80
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.days_since_last_declaration'),
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                colId: 'daysUntilLastDeclaration',
                valueGetter: (param) => {
                  if (param.data.mails) {
                    const mailsWithDeclaration = param.data.mails?.filter(mail => {
                      return mail.declarationId
                    })
                    if (mailsWithDeclaration.length > 0) {
                      mailsWithDeclaration.sort((mailA, mailB) => {
                        // decreasing
                        return this.$dayjs(mailA.declarationDate).isBefore(this.$dayjs(mailB.declarationDate)) ? 1 : -1
                      })
                      return Math.trunc(this.$dayjs().diff(this.$dayjs(mailsWithDeclaration[0].declarationDate), 'day'))
                    } else return ''
                  }
                },

                width: 80
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.declaration'),
                filter: 'agSetColumnFilter',
                filterParams: {
                  values: ['YES', 'NO', ''],
                  suppressAndOrCondition: true,
                  newRowsAction: 'keep' },
                colId: 'declaration',
                headerClass: 'pickup-step-19',

                valueGetter: (params) => {
                  if (params.data?.lastMail) {
                    if (params.data.lastMail.declarationId) {
                      return 'YES'
                    } else {
                      return 'NO'
                    }
                  }
                  return ''
                },

                cellRenderer: (params) => {
                  if (params.data.lastMail) {
                    if (params.data.lastMail.declarationId) {
                      if (params.data.lastMail.declarationComments) {
                        return 'YES <i class="fas fa-exclamation-circle has-text-warning"></i>'
                      }
                      return 'YES'
                    } else {
                      return 'NO'
                    }
                  } else {
                    return ''
                  }
                },
                cellClassRules: {
                  'rag-green': function (params) { return params.data.lastMail && params.data.lastMail.declarationId },
                  'rag-red': function (params) { return params.data.lastMail && !params.data.lastMail.declarationId }
                },

                width: 120
              }

            ]
          },
          {
            headerName: this.$t('pages.pickup_management.pickup_management.grid.last_inventory'),
            children: [
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.quantity'),
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  if (params.data.lastInventory) {
                    let sum = 0
                    params.data.lastInventory.forEach(l => { sum += l.inventory })
                    return sum.toString()
                  } else return ''
                }
              },
              {
                headerName: 'GPS52',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  if (params.data.lastInventory) {
                    const mat = params.data.lastInventory.find(row => {
                      return row.material === 'GPS52'
                    })
                    return mat ? mat.inventory : ''
                  }
                }
              },
              {
                headerName: 'GPS1',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  if (params.data.lastInventory) {
                    const mat = params.data.lastInventory.find(row => {
                      return row.material === 'GPS1'
                    })
                    return mat ? mat.inventory : ''
                  }
                }
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.grid.others'),
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  if (params.data.lastInventory) {
                    const quantities = params.data.lastInventory?.filter(row => {
                      return row.material !== 'GPS1' && row.material !== 'GPS52'
                    })
                    return quantities.length > 0 ? quantities.reduce((count, q) => count + parseInt(q.inventory), 0) : ''
                  }
                }
              }
            ]
          }
        ],
        detailRowHeight: 350,
        groupDefaultExpanded: 0,

        detailCellRendererParams: {

          // provide detail grid options
          detailGridOptions: {
            getContextMenuItems: this.secondaryActions,

            columnDefs: [
              {
                headerName: this.$t('pages.pickup_management.pickup_management.details_grid.last_sending_date'),
                colId: 'sendingDate',
                valueGetter: (params) => {
                  return this.$dayjs(params.data.lastSendingDate).local().format('DD-MM-YYYY HH:mm')
                },
                cellRenderer: 'agGroupCellRenderer'
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.details_grid.reminders_count'),
                colId: 'relaunchCount',
                valueGetter: function (params) {
                  return params.data.relaunchCount
                }
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.details_grid.declaration'),
                colId: 'declaration',
                cellRenderer: (params) => {
                  if (params.data.declarationId) {
                    if (params.data.declarationComments) {
                      return 'YES <i class="fas fa-exclamation-circle has-text-warning"></i>'
                    }
                    return 'YES'
                  } else {
                    return 'NO'
                  }
                },
                cellClassRules: {
                  'rag-green': function (params) { return params.data.declarationId },
                  'rag-red': function (params) { return !params.data.declarationId }
                },

                width: 60
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.details_grid.manual'),
                colId: 'manual',
                cellRenderer: (params) => {
                  if (params.data.manualDeclaration) {
                    return 'YES'
                  }
                },
                cellClassRules: {
                  'rag-orange': function (params) { return params.data.manualDeclaration }
                },

                width: 60
              },
              {
                headerName: this.$t('pages.pickup_management.pickup_management.details_grid.seen'),
                colId: 'declarationSeen',
                width: 120,
                filter: 'agTextColumnFilter',
                filterParams: { suppressAndOrCondition: true },

                valueGetter: (params) => {
                  if (params.data && params.data.declarationId) {
                    if (params.data.seen) {
                      return 'Yes'
                    } else {
                      return 'No'
                    }
                  }
                  return ''
                },
                cellClassRules: {
                  'rag-green': function (params) { return params.value === 'Yes' },
                  'rag-blue': function (params) { return params.value === 'No' }
                }
              }
            ],
            detailRowHeight: 200,
            groupDefaultExpanded: 0,
            masterDetail: true,
            detailCellRendererParams: {

              detailGridOptions: {
                columnDefs: [
                  { headerName: this.$t('pages.pickup_management.pickup_management.details_grid.reminders_sending_date'),
                    field: 'sendingDate',
                    filter: 'agTextColumnFilter',
                    cellRenderer: (data) => {
                      return this.$dayjs(data.value).local().format('DD-MM-YYYY')
                    },
                    width: 150
                  }
                ]
              },
              // extract and supply row data for detail
              getDetailRowData: async function (params) {
                const reminders = await EnduserDeclarationAdmin.getReminders(params.data.id)
                params.successCallback(reminders)
              }
            },
            onFirstDataRendered: function (params) {
              params.api.sizeColumnsToFit()
            }
          },
          getDetailRowData: function (params) {
            params.successCallback(params.data.mails)
          }

        }
      }

    }
  },
  computed: {
  },
  watch: {
    selectedGroup: async function (selectedGroup) {
      this.loadData()
      this.nextTourStep('selectGroup')
    },
    rangeDates: function () {
      this.loadData()
    }
  },
  mounted: function () {
    this.rangeDates = this.rangeDatesDefault
    this.hideSideBar()
  },
  methods: {
    ...mapActions(['switchSideBar', 'hideSideBar', 'archiveAction']),
    switchRowSelection () {
      this.gridOptions.api.deselectAll()
      if (!this.rowSelection) {
        this.rowSelection = 'multiple'
      } else {
        this.rowSelection = null
      }
    },
    stopTour () {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.columnApi.setColumnVisible('emails', true)
        this.gridOptions.columnApi.setColumnVisible('enduserTel', true)
        this.gridOptions.columnApi.setColumnVisible('country', true)
        this.gridOptions.columnApi.setColumnVisible('daysUntilFirstReminder', true)
        this.gridOptions.columnApi.setColumnVisible('daysUntilLastDeclaration', true)
      }
    },
    startTour () {
      this.$tours['pickupManagementTour'].start()
      this.hideSideBar()
      this.gridOptions.columnApi.setColumnVisible('emails', false)
      this.gridOptions.columnApi.setColumnVisible('enduserTel', false)
      this.gridOptions.columnApi.setColumnVisible('country', false)
      this.gridOptions.columnApi.setColumnVisible('daysUntilFirstReminder', false)
      this.gridOptions.columnApi.setColumnVisible('daysUntilLastDeclaration', false)
    },
    nextTourStep: function (action) {
      if (this.$refs.pickupTour) {
        this.$refs.pickupTour.nextTourStep(action)
      }
    },
    getSavedFilters: function () {
      if (this.selectedGroup) {
        if (this.gridOptions.api) {
          setTimeout(() => {
            this.gridOptions.api.setFilterModel(JSON.parse(localStorage.getItem('pickupGridFilters')) || null)
            this.gridOptions.api.refreshCells()
          }, 500)
        }
      }
    },
    loadData: async function () {
      try {
        if (this.selectedGroup && this.rangeDates) {
          this.loading = true

          const data = await EnduserDeclarationAdmin.getPickupData({ 'collectGroup': this.selectedGroup.collectGroup, startDate: this.$dayjs(this.rangeDates[0]).format('YYYY-MM-DD'), endDate: this.$dayjs(this.rangeDates[1]).format('YYYY-MM-DD') })
          this.mailTemplates = data.pickupData.mailTemplates
          this.filteredEndusers = this.computedEndusers = data.endusers
          this.notSeenDeclaration = this.computedEndusers.filter(c => {
            return c.mails?.filter(m => m.seen === false).length > 0
          })
          this.loading = false
          this.getSavedFilters()
        }
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        this.loading = false
      }
    },
    async refresh () {
      this.loadData()
      this.gridOptions.api.refreshCells()
    },
    gridReady: async function () {
      try {
        if (this.$tours['pickupManagementTour'].currentStep !== -1) {
          this.gridOptions.columnApi.setColumnVisible('emails', false)
          this.gridOptions.columnApi.setColumnVisible('enduserTel', false)
          this.gridOptions.columnApi.setColumnVisible('country', false)
          this.gridOptions.columnApi.setColumnVisible('daysUntilFirstReminder', false)
          this.gridOptions.columnApi.setColumnVisible('daysUntilLastDeclaration', false)
        }
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    seeDeclaration: async function (mail) {
      try {
        this.selectedMailDeclaration = await EnduserDeclarationAdmin.getDeclaration(mail.declarationId)
        this.declarationQuantities = await EnduserDeclarationAdmin.getQuantities(mail.declarationId)
        await EnduserDeclarationAdmin.updateSeeDeclaration(mail.declarationId)
        this.showDeclaration = true
        mail.seen = true
        this.archiveAction(`[Pickup management] Has seen the declaration of ${mail.enduserCode} with id : ${mail.declarationId}`)
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    },
    secondaryActions (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (params.node.data && params.node.data.declarationId) {
        customMenu.push({
          name: this.$t('pages.pickup_management.pickup_management.grid.see_declaration'),
          action: () => {
            this.seeDeclaration(params.node.data)
          },
          icon: '<i class="fas fa-fw fa-barcode" ></i>'
        })
      }

      if (params.node.data && params.node.data.manualDeclaration) {
        customMenu.push({
          name: this.$t('pages.pickup_management.pickup_management.details_grid.delete'),
          action: () => {
            this.deleteMail(params.node.data)
          },
          icon: '<i class="fas fa-fw fa-times" ></i>'
        })
      }

      if (params.node.data && params.node.data.declarationId) {
        customMenu.push({
          name: this.$t('pages.pickup_management.pickup_management.grid.download_report'),
          action: () => {
            this.getPdfReport(params.node.data.token)
          },
          icon: '<i class="fas fa-fw fa-download" ></i>'
        })
      }
      const result = [...customMenu, ...genericMenu]
      return result
    },
    mainActions (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      const selectedRows = this.gridOptions.api.getSelectedRows()
      if (selectedRows.length > 1) {
        customMenu.push({
          name: this.$t('pages.pickup_management.pickup_management.grid.set_mail_template'),
          action: () => {
            if (!params.node.data.emails?.length) {
              this.noEmailError()
            } else {
              this.selectedEnduserCode = params.node.data.enduserCode
              this.settingMailTemplate = true
              this.nextTourStep('setMailTemplate')
            }
          },
          icon: '<i class="fas fa-fw fa-envelope" ></i>'
        })
      } else {
        if (params.node && params.node.data) {
          if (params.node.data.interval) {
            customMenu.push({
              name: this.$t('pages.pickup_management.pickup_management.grid.set_mail_template'),
              action: () => {
                if (!params.node.data.emails?.length) {
                  this.noEmailError()
                } else {
                  this.selectedEnduserCode = params.node.data.enduserCode
                  this.settingMailTemplate = true
                  this.nextTourStep('setMailTemplate')
                }
              },
              icon: '<i class="fas fa-fw fa-envelope" ></i>'
            })
          }
          if (params.node.data.coords) {
            customMenu.push({
              name: this.$t('pages.pickup_management.pickup_management.grid.see_location'),
              action: () => {
                this.selectedEnduserCode = params.node.data.enduserCode
                this.selectedEnduserCoords = params.node.data.coords
                this.showMap = true
              },
              icon: '<i class="fas fa-fw fa-thumbtack" ></i>'
            })
          }
          if (params.node.data.lastMail && params.node.data.lastMail.declarationId) {
            customMenu.push({
              name: this.$t('pages.pickup_management.pickup_management.grid.see_declaration'),
              action: () => {
                this.seeDeclaration(params.node.data.lastMail)
              },
              icon: '<i class="fas fa-fw fa-barcode" ></i>'
            })
          }
          if (params.node.data.lastMail && params.node.data.lastMail.declarationId) {
            customMenu.push({
              name: this.$t('pages.pickup_management.pickup_management.grid.download_report'),
              action: () => {
                this.getPdfReport(params.node.data.lastMail.token)
              },
              icon: '<i class="fas fa-fw fa-download" ></i>'
            })
          }
          customMenu.push({
            name: this.$t('pages.pickup_management.pickup_management.grid.manual_declaration'),
            action: () => {
              if (!params.node.data.emails?.length) {
                this.noEmailError()
              } else {
                this.manualDeclaration(params.node.data.enduserCode)
              }
            },
            icon: '<i class="fas fa-fw fa-user-edit" ></i>'
          })
          customMenu.push({
            name: this.$t('pages.pickup_management.pickup_management.grid.statistics'),
            action: () => {
              this.selectedMails = params.node.data
              this.showStats = true
            },
            icon: '<i class="fas fa-fw fa-chart-bar" ></i>'
          })
          customMenu.push({
            name: this.$t('pages.pickup_management.pickup_management.grid.add_enduser_comment'),
            action: () => {
              this.settingEnduserComments = true
              this.selectedEnduser = params.node.data
              if (this.selectedEnduser.infos) {
                this.tmp = this.selectedEnduser.infos.comments
              } else {
                this.tmp = ''
              }
            },
            icon: '<i class="fas fa-fw fa-comments" ></i>'
          })
          if (params.node.data.mailTemplate) {
            customMenu.push({
              name: this.$t('pages.pickup_management.pickup_management.grid.send_mail'),
              action: async () => {
                if (!params.node.data.emails?.length) {
                  this.noEmailError()
                } else {
                  this.$buefy.dialog.confirm({
                    title: this.$t('pages.pickup_management.pickup_management.send_mail_modal.title'),
                    message: this.$t('pages.pickup_management.pickup_management.send_mail_modal.message'),
                    confirmText: this.$t('pages.pickup_management.pickup_management.send_mail_modal.confirm'),
                    cancelText: this.$t('pages.pickup_management.pickup_management.send_mail_modal.cancel'),
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: async () => {
                      try {
                        if (params.node.data.lastMail && Math.trunc(this.$dayjs().diff(this.$dayjs(params.node.data.lastMail.lastSendingDate).local(), 'minutes')) < 30) {
                          throw Error(this.$t('pages.pickup_management.pickup_management.error_send_mail'))
                        } else {
                          await EnduserDeclarationAdmin.sendMail(params.node.data.enduserCode)
                          this.$buefy.toast.open({ message: this.$t('pages.pickup_management.pickup_management.success'), type: 'is-primary' })
                          this.refresh()
                        }
                      } catch (error) {
                        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
                      }
                    }
                  })
                }
              },
              icon: '<i class="fas fa-fw fa-paper-plane" ></i>'
            })
          }
        }
      }

      const result = [...customMenu, ...genericMenu]
      return result
    },
    setMailTemplate: async function () {
      try {
        let promises = []
        const selectedRows = this.gridOptions.api.getSelectedRows()
        if (selectedRows.length > 1) {
          for (let index in selectedRows) {
            const enduser = selectedRows[index]
            if (enduser.emails.length === 0) {
              throw Error(this.$t('pages.pickup_management.pickup_management.errors.enduser_has_no_mail'))
            }
            if (!enduser.interval) {
              throw Error(this.$t('pages.pickup_management.pickup_management.errors.enduser_has_no_interval'))
            }
            promises.push(await EnduserDeclarationAdmin.setMailTemplate({ enduserCode: enduser.enduserCode, templateId: this.selectedMailTemplate.id }))
            this.archiveAction(`[Pickup management] Has assigned a mail template to ${enduser.enduserCode} with id : ${this.selectedMailTemplate.id}`)
          }
          await Promise.all(promises)
        } else {
          await EnduserDeclarationAdmin.setMailTemplate({ enduserCode: this.selectedEnduserCode, templateId: this.selectedMailTemplate.id })
          this.archiveAction(`[Pickup management] Has assigned a mail template to ${this.selectedEnduserCode} with id : ${this.selectedMailTemplate.id}`)
        }
        this.nextTourStep('closeSettingMailTemplate')
        this.$buefy.toast.open({ type: 'is-primary', message: 'Updated', duration: 3000 })
        this.settingMailTemplate = false
        this.refresh()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
      this.selectedEnduserCode = null
      this.selectedMailTemplate = null
      this.settingMailTemplate = false
    },
    flagRender: function (params) {
      if (params.data) {
        const flag = `<img src="https://flagcdn.com/w20/${params.data.countryCode.toLowerCase()}.png" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px;">`
        return flag + ' ' + params.value
      } else {
        return params.value
      }
    },
    manualDeclaration: async function (enduserCode) {
      try {
        const token = await EnduserDeclarationAdmin.manualDeclaration(enduserCode)
        this.refresh()
        window.open('https://partner.fr-gps.com/consignee-declaration?token=' + token, '_blank')
        // window.open('http://localhost:8081/consignee-declaration?token=' + token, '_blank')
        this.archiveAction(`[Pickup management] Started a "manual declaration" proccess on the enduser: ${enduserCode} (row created but no declaration submited yet)`, `token : ${token}`)
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    },
    async deleteMail (mail) {
      this.$buefy.dialog.confirm({
        title: this.$t('pages.pickup_management.pickup_management.delete_mail_modal.title'),
        message: this.$t('pages.pickup_management.pickup_management.delete_mail_modal.message'),
        confirmText: this.$t('pages.pickup_management.pickup_management.delete_mail_modal.confirm'),
        cancelText: this.$t('pages.pickup_management.pickup_management.delete_mail_modal.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await EnduserDeclarationAdmin.deleteMail(mail.id)
            this.$buefy.toast.open({ type: 'is-primary', message: this.$t('pages.pickup_management.pickup_management.success'), duration: 3000 })
            this.refresh()
            this.archiveAction(`[Pickup management] Deleted a mail (manual) on enduser ${mail.enduserCode} with token : ${mail.token}`)
          } catch (error) {
            this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
          }
        }
      })
    },
    getPdfReport: async function (token) {
      try {
        await EnduserDeclarationAdmin.getPdfReport(token)
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    closeStatsScreen: function () {
      this.showStats = false
    },
    closeSignatureScreen: function () {
      this.showSignature = false
    },
    closeMailTemplates: function () {
      this.showMailTemplates = false
    },
    setEnduserComments: async function () {
      try {
        await EnduserDeclarationAdmin.setEnduserInfos({
          enduserCode: this.selectedEnduser.enduserCode,
          comments: this.selectedEnduser.infos.comments
        })
        this.settingEnduserComments = false
        this.$buefy.toast.open({ message: this.$t('pages.pickup_management.pickup_management.success'), type: 'is-primary' })
        this.selectedEnduser = null
        this.refresh()
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    filterChanged: function (e) {
      if (e.afterFloatingFilter != null) {
        localStorage.setItem('pickupGridFilters', JSON.stringify(this.gridOptions.api.getFilterModel()))
      }
    },
    clearFilters: function () {
      localStorage.setItem('pickupGridFilters', JSON.stringify({}))
      this.gridOptions.api.setFilterModel(JSON.parse(localStorage.getItem('pickupGridFilters')))
      this.rangeDates = this.rangeDatesDefault
    },
    showNotSeenDeclarations: function () {
      if (this.filteredEndusers !== this.computedEndusers) {
        this.filteredEndusers = this.computedEndusers
      } else {
        this.filteredEndusers = this.notSeenDeclaration
      }
    },
    noEmailError: function () {
      this.$buefy.toast.open({ message: this.$t('pages.pickup_management.pickup_management.errors.enduser_without_emails'), type: 'is-danger', duration: 5000 })
    }

  }
}
</script>

<style scoped>
/* #mapid { height: 180px; } */

.commentsSection {
  display: block;
  padding: 1rem;
}

.custom-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.mb {
  margin-bottom: 1rem;
}

.tour-div {
  min-height: 150px;
}

#pickupTourContainer {
  height: 0px;
  position: absolute;
  right: 0px;
  top: -8rem;
  z-index: 60;
  margin-right: 1rem;
  min-width: 120px;
}

.count {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
  background-color: red;
  border-radius: 15px;
  min-width: 25px;
  min-height: 25px;
}

</style>
