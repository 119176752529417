<template>
  <div class="modal-card" style="width: auto">
    <button class="button is-warning is-small" @click="$parent.close()">
      <b-icon icon="chevron-left" />
      <span>{{ $t('global.back') }}</span>
    </button>
    <VueLeafletMap
      :markers="markers"
      name="leaflet"
      map-type="pickup"
      :selected-group="selectedGroup.collectGroup"
      :selected-marker="selectedMarker"
    >
      <map-filter :markers="markers" @filter-changed="(markers) => filteredMarkers = markers" @marker-selected="focusOnMarker" />
    </VueLeafletMap>
  </div>
</template>

<script>

import VueLeafletMap from '@/components/map/vue-leafletmap'
import { pickupMTypes } from '@/components/map/pickupMTypes'
import mapMethods from '@/mixins/mapMethods'
import mapFilter from '@/components/map/map-filter'

export default {
  components: {
    VueLeafletMap,
    mapFilter
  },
  mixins: [mapMethods],
  props: {
    endusers: {
      type: Array,
      required: true
    },
    enduserCode: {
      type: String,
      default: ''
    },
    collects: {
      type: Array,
      default: () => null
    },
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      markers: [],
      markersParams: [],
      endusersCopy: [],
      filteredMarkers: [],
      selectedMarker: null
    }
  },
  computed: {
    computedMarkers: function () {
      return this.endusersCopy.map(m => {
        if (m.lastInventory && m.lastInventory.length > 0 && m.lastCollect && m.lastCollect.length > 0) {
          m.hasUncollectedStock = this.$dayjs(m.lastInventory[0].date).isAfter(this.$dayjs(m.lastCollect[0]?.loadingDate))
        } else if ((!m.lastCollect || m.lastCollect?.length === 0) && m.lastInventory?.length > 0) {
          m.hasUncollectedStock = true
        } else {
          m.hasUncollectedStock = false
        }
        return m
      })
    }
  },
  watch: {
    computedMarkers: function () {
      this.createMarkers()
    }
  },
  mounted: function () {
    try {
      this.loadData()

      if (this.enduserCode) {
        const selected = this.endusersCopy.find(e => e.enduserCode === this.enduserCode)
        this.focusOnMarker(selected)
      }
    } catch (error) {
      this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
    }
  },
  methods: {
    loadData: function () {
      // this.markers = []
      this.enduserMarkerType = pickupMTypes.get('enduser')
      this.declarationMarkerType = pickupMTypes.get('stock')
      this.convertData()
      // this.createMarkers()
    },
    createMarkers: function () {
      let endusers = this.endusersCopy.filter(e => !e.hasUncollectedStock)
      let enduserWithDeclaration = this.endusersCopy.filter(e => e.hasUncollectedStock)
      this.markers.push(...this.generateMarkers('enduser', endusers, this.enduserMarkerType.markerIcon))
      this.markers.push(...this.generateMarkers('Not yet collected', enduserWithDeclaration, this.declarationMarkerType.markerIcon))
    },
    convertData: function () {
      this.endusersCopy = this.endusers.map(e => {
        e.name = e.enduserName
        e.selected = e.enduserCode === this.enduserCode
        return e
      })
    },
    focusOnMarker (marker) {
      this.selectedMarker = marker
    }
  }
}
</script>

<style>

</style>
